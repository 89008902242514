export enum PageType {
  newHomesList = 'NEW_HOMES_LIST',
  newHomesDetail = 'NEW_HOMES_DETAIL'
}

export const newHomesByRegion = [
  {
    displayName: 'London',
    webName: 'london'
  },
  {
    displayName: 'East London',
    webName: 'east-london'
  },
  {
    displayName: 'South London',
    webName: 'south-london'
  },
  {
    displayName: 'Surrey',
    webName: 'surrey'
  },
  {
    displayName: 'Central London',
    webName: 'central-london'
  },
  {
    displayName: 'North London',
    webName: 'north-london'
  },
  {
    displayName: 'West London',
    webName: 'west-london'
  },
  {
    displayName: 'UK',
    webName: 'uk'
  }
];

export const newHomesMenuItem = [
  {
    displayName: 'Central London',
    value: 'central-london'
  },
  {
    displayName: 'East London',
    value: 'east-london'
  },
  {
    displayName: 'North London',
    value: 'north-london'
  },
  {
    displayName: 'South London',
    value: 'south-london'
  },
  {
    displayName: 'West London',
    value: 'west-london'
  },
  {
    displayName: 'Surrey',
    value: 'surrey'
  },
  {
    displayName: 'All areas',
    value: 'uk'
  }
];

export const newHomeSortingTypes = [
  {
    label: 'Order price (high-low)',
    value: 'HighestPrice'
  },
  {
    label: 'Order price (low-high)',
    value: 'LowestPrice'
  },
  {
    label: 'Order A-Z',
    value: 'Alphabatical'
  }
];

export const newHomeViewTypes = [
  {
    label: 'List & Map view',
    value: 'list-and-map-view'
  },
  {
    label: 'List view',
    value: 'list-view'
  },
  {
    label: 'Map view',
    value: 'map-view'
  }
];

export enum DevelopmentInstructionType {
  SALE = 'sale',
  LETTING = 'letting'
}

export enum DevelopmentDetailButton {
  FIND_RENTAL_VALUES = 'find-rental-values-button',
  VIDEO = 'video-button',
  CONTACT_US = 'contact-us-button'
}
