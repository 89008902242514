// NOTE: Do no remove commmented APIs Urls until regression testing is complete.

import {
  inverseInstructionMappings,
  parseFooterMenu,
  parseHeaderMenu,
  renameKeys,
  formatDateTime
} from '@utils/helperFunctions';
import { Method, RequestConfig } from 'types/requestConfig';
import API_ENDPOINTS from './apiEndpoints';
import { httpCMSRequest, httpRequest, bosWsiRequest } from './httpClient';

import type {
  PayloadType as AnswerPayloadType,
  QuestionSchemaTypes
} from '@layouts/PreQualification/types';
import {
  ChangeOfOccupancyRequestPayload,
  ContactPrivateOfficePayload,
  UpdateUserContactPayload
} from './types';
import { SubmitViewingEnquiryPayload } from './types';
import { DevelopmentInstructionType } from '@utils/Constants/newHomesConstant';
import { getEnvVarOrDefault } from '@foxtons/fdl/utils/httpRequest/utils';
import { refreshUserSilently } from '@foxtons/fdl/utils/user';

interface LatLon {
  lat: number;
  lon: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type HttpResponseDataType = any; //we will never know what would be the response type if strapi data is dynamically generated on run time

export interface BoundingBox {
  topLeft: LatLon;
  bottomRight: LatLon;
}

interface SubscriptionCategory {
  subscriptionCategoryId: number;
  subscriptionCategoryCode: string;
  subscriptionCategoryType: string;
  subscriptionCategoryWebDescription: string;
  subscriptionCategoryEmailEnabled: boolean;
  subscriptionCategorySmsEnabled: boolean;
}

interface ContactSubscription {
  contactSubscriptionCategoryEmail: boolean;
  contactSubscriptionCategorySms: boolean;
}

export interface SubscriptionInfo {
  subscriptionCategory: SubscriptionCategory;
  contactSubscription: ContactSubscription;
}
export interface UpdatedSubscriptions {
  contactSubscriptionCategoryEmail: boolean;
  contactSubscriptionCategorySms: boolean;
  categoryId: number;
}

export interface LettingFormValues {
  title: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  homeTelephone: string;
  valuationAddress: {
    addressLine2: string;
    addressLine3: string;
    addressCounty: string;
    addressCountryCode: string;
    addressLine1: string;
    addressTown: string;
    addressPostcode: string;
  };
  rentalPrice: string;
  tenancyDate: string;
  webInterest: {
    seller: boolean;
    landlord: boolean;
    buyer: boolean;
    renter: boolean;
  };
  MarketingOption: boolean;
}

export interface ValuationInterface {
  title: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  workTelephone: string;
  valuationAddress: {
    addressLine1: string;
    addressLine2?: string;
    addressLine3?: string;
    addressTown?: string;
    addressCounty?: string;
    addressPostcode: string;
    addressCountryCode?: string;
  };
  postCode: string;
  marketingOptIn: boolean;
  legalOwner: string;
  contactSituation?: string;
  contactNotes?: string;
  valuationMethod: string;
  valuationType: string;
  bedrooms: number;
  propertyType: number;
  propertySubType: string;
  webInterest?: {
    seller: boolean;
    landlord: boolean;
    buyer: boolean;
    renter: boolean;
  };
  date?: string;
  time?: string;
}

export interface ArrangeViewingsFieldProps {
  title: string;
  firstName: string;
  lastName: string;
  homePhone: string;
  phoneNumber: string;
  email: string;
  preference?: string;
  date: Date;
  time: Date[];
}

export interface MortgageAdviceFieldProps {
  firstName?: string;
  lastName?: string;
  phoneNumber?: number | null;
  email?: string;
  helpType?: string;
}

export interface CreateAlertProps {
  searchName?: string;
  emailSubscription?: boolean;
  broadcastFrequency?: number;
  processingDay?: string;
  searchProfileUrl?: string;
  searchProfileMeta?: string;
}

export const fetchMegaMenu = async () => {
  const headerConfig: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.cms.megaMenu
  };

  const header = await httpCMSRequest(headerConfig);
  const headerResult = header.data?.data?.attributes?.items?.data;

  return parseHeaderMenu(headerResult);
};

export const fetchFooter = async () => {
  const headerConfig: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.cms.footerMenu
  };

  const footer = await httpCMSRequest(headerConfig);
  const footerResult = footer?.data?.data?.attributes;
  const renamedFooter = renameKeys(footerResult);

  return parseFooterMenu(renamedFooter);
};

export const getStampDutyCalc = async (price: number | string) => {
  const url = `${API_ENDPOINTS.stampDutyCalculationResult}?PurchasePrice=${
    price || 0
  }&IsUkResident=${true}&IsFirstTimeBuyer=${true}&IsSecondHome=${false}`;
  const options = {
    method: 'GET',
    url
  };

  const response = await httpRequest(options);

  if (response?.status === 200) {
    return response;
  }
};

export const togglePropertyFavorite = async (
  instructionType: string,
  propertyReference: string,
  isPropertyFavorite: boolean
) => {
  const config: RequestConfig = {
    method: isPropertyFavorite ? 'DELETE' : 'PUT',
    url: isPropertyFavorite
      ? `${API_ENDPOINTS.deleteSavedProperty(
          propertyReference,
          inverseInstructionMappings[instructionType]
        )}`
      : `${API_ENDPOINTS.savedProperties}`,
    data: !isPropertyFavorite
      ? {
          propertyRegCode: propertyReference,
          instructionType: inverseInstructionMappings[instructionType]
        }
      : undefined
  };

  try {
    const response = await httpRequest(config);

    return { success: true, response, error: null };
  } catch (error) {
    return { success: false, response: null, error };
  }
};

export const getPropertyNote = async (propertyReference: string) => {
  const config: RequestConfig = {
    method: 'GET',
    url: `${API_ENDPOINTS.savedProperties}/?PropertyReferences=${propertyReference}`
  };
  const response = await httpRequest(config);

  try {
    return { success: true, response, error: null };
  } catch (error) {
    return { success: false, response: null, error };
  }
};

export const updatePropertyNote = async (
  propertyReference: string,
  instructionType: string,
  notes: string
) => {
  const config: RequestConfig = {
    method: 'PUT',
    url: API_ENDPOINTS.savedProperties,
    data: {
      propertyRegCode: propertyReference,
      instructionType: inverseInstructionMappings[instructionType],
      contactRating: 0,
      contactNotes: notes
    }
  };

  try {
    const response = await httpRequest(config);

    return { success: true, response, error: null };
  } catch (error) {
    return { success: false, response: null, error };
  }
};

export const validateProperty = async (
  instructionType: string,
  propertyReference: string
) => {
  try {
    const validationReqConfig: RequestConfig = {
      method: 'GET',
      url: API_ENDPOINTS.validateProperty(
        propertyReference,
        inverseInstructionMappings[instructionType]
      )
    };
    const res = await httpRequest(validationReqConfig);

    if (res.status === 204) {
      return { success: true, response: res, error: null };
    } else {
      return { success: false, response: res, error: null };
    }
  } catch (e) {
    return { success: false, response: null, error: e };
  }
};

const arrangeViewingEnquiryType = (
  isDevelopment = false,
  isDevelopmentUnit = false,
  instructionType: string
) => {
  if (instructionType === 'sale' && isDevelopment) {
    // new homes
    return isDevelopmentUnit ? 'DDI' : 'DDV';
  }

  // btr and others
  return inverseInstructionMappings[instructionType];
};

export function updateUserProfile(
  contactId: string,
  payload: UpdateUserContactPayload
) {
  const config: RequestConfig = {
    method: 'PUT',
    url: `${API_ENDPOINTS.user}/${contactId}`,
    data: payload
  };

  return httpRequest(config);
}

export const arrangeViewing = async (
  instructionType: string,
  propertyReference: string,
  values: ArrangeViewingsFieldProps,
  user: any,
  isDevelopment = false,
  isDevelopmentUnit = false
) => {
  if (
    user?.profile?.updateAccess &&
    user.profile.updateAccess !== 'NONE' &&
    user.contactId
  ) {
    try {
      await updateUserProfile(user.contactId, {
        title: values.title,
        firstName: values.firstName,
        lastName: values.lastName,
        homeTelephone: values.homePhone,
        mobileTelephone: values.phoneNumber,
        emailAddress: values.email,
        // address is not available in the form, so we are using the user's address
        contactAddress: user.profile.contactAddress
      });
      await refreshUserSilently();
    } catch (e) {
      return { success: false, response: null, error: e };
    }
  }

  const dateFrom = values.time[0];

  dateFrom.setMinutes(0);
  dateFrom.setSeconds(0);
  dateFrom.setMilliseconds(0);
  const dateTo = values.time[1];

  dateTo.setMinutes(0);
  dateTo.setSeconds(0);
  dateTo.setMilliseconds(0);
  const config: RequestConfig = {
    method: 'POST',
    url: `${API_ENDPOINTS.viewing}`,
    data: {
      propertyRegCode: propertyReference,
      enquiryType: arrangeViewingEnquiryType(
        isDevelopment,
        isDevelopmentUnit,
        instructionType
      ),
      viewingRequestFromDatetime: dateFrom.toISOString(),
      viewingRequestToDatetime: dateTo.toISOString(),
      viewingComment: values.preference,
      contactTracking: null
    }
  };

  try {
    const response = await httpRequest(config);

    if (response.status === 204) {
      return { success: true, response, error: null };
    }

    return { success: false, response, error: null };
  } catch (error) {
    return { success: false, response: null, error: error };
  }
};

export const getMortgageAdvice = async (values: MortgageAdviceFieldProps) => {
  const config: RequestConfig = {
    method: 'POST',
    url: `${API_ENDPOINTS.mortgageAdviceLead}`,
    data: {
      firstName: values?.firstName,
      lastName: values?.lastName,
      emailAddress: values?.email,
      mobileTelephone: values?.phoneNumber?.toString(),
      comments: values?.helpType
    }
  };

  try {
    const response = await httpRequest(config);

    if (response.status === 200) {
      return { success: true, response, error: null };
    }

    return { success: false, response, error: null };
  } catch (error) {
    return { success: false, response: null, error };
  }
};

// TODO: Need to revist API (enquiryType, customTrackingFields)
export const getUnderOffer = async (
  propertyId: string,
  instructionType: string,
  contactId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any,
  user: any
) => {
  if (
    user?.profile?.updateAccess &&
    user.profile.updateAccess !== 'NONE' &&
    user.contactId
  ) {
    try {
      await updateUserProfile(user.contactId, {
        title: values.title,
        firstName: values.firstName,
        lastName: values.lastName,
        homeTelephone: values.homeTelephone,
        mobileTelephone: values.mobileTelephone,
        emailAddress: values.emailAddress,
        // address is not available in the form, so we are using the user's address
        contactAddress: user.profile.contactAddress
      });
      await refreshUserSilently();
    } catch (e) {
      return { success: false, response: null, error: e };
    }
  }

  const config: RequestConfig = {
    method: 'POST',
    url: `${API_ENDPOINTS.underOffer(contactId)}`,
    data: {
      propertyRegCode: propertyId,
      enquiryType: inverseInstructionMappings[instructionType],
      enquiryComment: values?.preference || '',
      utmSource: '',
      utmMedium: '',
      utmCampaign: '',
      utmTerm: '',
      utmContent: '',
      customTrackingFields: 'na',
      title: values?.title,
      firstName: values?.firstName,
      lastName: values?.lastName,
      emailAddress: values?.emailAddress,
      homeTelephone: values?.homeTelephone?.toString(),
      mobileTelephone: values?.mobileTelephone?.toString(),
      address: values?.address || ''
    }
  };

  try {
    const response = await httpRequest(config);

    if (response.status === 204) {
      return { success: true, response, error: null };
    }

    return { success: false, response, error: null };
  } catch (error) {
    return { success: false, response: null, error };
  }
};

export async function getBlogArticleList({
  page = 1,
  category = '',
  pageSize = 1
}: {
  page?: number;
  pageSize?: number;
  category?: string;
}) {
  // request method and endpoint configuration
  const config: RequestConfig = {
    method: 'GET',
    url: `${API_ENDPOINTS.blogArticle}?Page=${page}&PageSize=${pageSize}${
      category ? '&categories=' + category : ''
    }`
  };

  try {
    const response = await httpRequest(config);

    return { response, success: true };
  } catch (error) {
    return { success: false, response: error };
  }
}

export async function getBlogArticleDetail(slug: string) {
  // request method and endpoint configuration
  const config: RequestConfig = {
    method: 'GET',
    url: `${API_ENDPOINTS.blogArticle}/${slug}`
  };

  try {
    const response = await httpRequest(config);

    return { response, success: true };
  } catch (error) {
    return { success: false, response: error };
  }
}

export async function getBlogArticleDetailContent() {
  const options = {
    url: `${API_ENDPOINTS.cms.blogArticleDetail}`
  };

  try {
    const response = await httpCMSRequest(options);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export const getSchoolsList = async (
  payload: BoundingBox,
  schoolType: string,
  options?: { signal?: AbortController['signal'] }
) => {
  const config: RequestConfig = {
    method: 'POST',
    url: `${API_ENDPOINTS.schoolBoundedBox}?PageSize=100&page=1&schoolType=${schoolType}`,
    data: {
      topLeft: {
        lat: payload?.topLeft.lat?.toString(),
        lon: payload?.topLeft.lon?.toString()
      },
      bottomRight: {
        lat: payload?.bottomRight.lat?.toString(),
        lon: payload?.bottomRight.lon?.toString()
      }
    },
    ...options
  };

  try {
    const response = await httpRequest(config);

    return { success: true, response, error: null };
  } catch (error) {
    return { success: false, response: null, error };
  }
};

export const getAllSchoolsList = async () => {
  const config: RequestConfig = {
    method: 'GET',
    url: `${API_ENDPOINTS.schoolDiscover}?PageSize=1000&page=1`
  };

  try {
    const response = await httpRequest(config);

    return { success: true, response, error: null };
  } catch (error) {
    return { success: false, response: null, error };
  }
};

export const getStationsList = async (payload: BoundingBox) => {
  const config: RequestConfig = {
    method: 'POST',
    url: `${API_ENDPOINTS.stationBoundedBox}?PageSize=100&page=1`,
    data: {
      topLeft: {
        lat: payload?.topLeft.lat.toString(),
        lon: payload?.topLeft.lon.toString()
      },
      bottomRight: {
        lat: payload?.bottomRight.lat.toString(),
        lon: payload?.bottomRight.lon.toString()
      }
    }
  };

  try {
    const response = await httpRequest(config);

    return { success: true, response, error: null };
  } catch (error) {
    return { success: false, response: null, error };
  }
};

export async function getPropertiesByPostCode(
  payload: string,
  signal?: AbortSignal
) {
  // Request method and endpoint configuration
  const config: RequestConfig = {
    method: 'GET',
    url: `${API_ENDPOINTS.search}/${API_ENDPOINTS.locations}/${payload}/${API_ENDPOINTS.addresses}`,
    signal
  };

  try {
    const response = await httpRequest(config);

    return { response: response?.data?.data, success: true };
  } catch (error) {
    return { response: error, success: false };
  }
}

export async function getPropertiesAutoComplete(
  payload: string,
  signal?: AbortSignal
) {
  // request method and endpoint configuration
  const config: RequestConfig = {
    method: 'GET',
    url: `${API_ENDPOINTS.autoCompleteProperty}${payload}&postcodeOnly=true`,
    signal
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response: response?.data
    };
  } catch (error) {
    return { success: false, response: error }; // call reducer to match case of fail and update store
  }
}

export async function postContactUsForm(payload: {
  title: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  mobileTelephone: string;
  comments: string;
  subject: string;
}) {
  const config: RequestConfig = {
    method: 'POST',
    url: API_ENDPOINTS.contactUs,
    data: payload
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response: response
    };
  } catch (error) {
    return {
      success: false,
      response: error as HttpResponseDataType
    };
  }
}

export async function postGeneralContactUsForm(
  payload: {
    title: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    mobileTelephone: string;
    sentTo: string;
    address: string; //honeypot attribute
  },
  formName: string
) {
  const config: RequestConfig = {
    method: 'POST',
    url: `${API_ENDPOINTS.contactUsForm(formName)}`,
    data: payload
  };

  try {
    const response = await httpRequest(config);

    return { success: true, response, error: null };
  } catch (error) {
    return { success: false, response: null, error };
  }
}

export async function postRentalEnquiryForm(payload: {
  title: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  mobileTelephone: string;
  comments: string;
  subject: string;
  formName: string;
  developmentReference: string;
  address: string; //honeypot attribute
}) {
  const config: RequestConfig = {
    method: 'POST',
    url: `${API_ENDPOINTS.rentalEnquiry}`,
    data: payload
  };

  try {
    const response = await httpRequest(config);

    return { success: true, response, error: null };
  } catch (error) {
    return { success: false, response: null, error };
  }
}

export async function postValuation(payload: Partial<ValuationInterface>) {
  const config: RequestConfig = {
    method: 'POST',
    url:
      payload.valuationMethod === 'online'
        ? `${API_ENDPOINTS.valuation}`
        : `${API_ENDPOINTS.booking}`,

    data: payload
  };

  try {
    const response = await httpRequest(config);

    return { response: response, success: true };
  } catch (error) {
    return { response: error, success: false };
  }
}

export async function postLettingPropertyForm(
  payload: LettingFormValues,
  user: any
) {
  // request method and endpoint configuration
  const config: RequestConfig = {
    method: 'POST',
    url: `${API_ENDPOINTS.valuationDetails}/${API_ENDPOINTS.findTenants}/${
      user?.contactId || ''
    }`,

    data: payload
  };

  try {
    const response = await httpRequest(config);

    return { response, success: true };
  } catch (error) {
    return { response: error as HttpResponseDataType, success: false };
  }
}

export async function getStampDutyCalculatorResult(payload: {
  purchasePrice: string;
  isFirstTimeBuyer: boolean;
  isUkResident: boolean;
  isSecondHome: boolean;
}) {
  // request method and endpoint configuration

  const url = `${API_ENDPOINTS.stampDutyCalculationResult}?PurchasePrice=${payload.purchasePrice}&IsUkResident=${payload.isUkResident}&IsFirstTimeBuyer=${payload.isFirstTimeBuyer}&IsSecondHome=${payload.isSecondHome}`;

  try {
    const response = await httpRequest({
      url: `${url}`
    });

    return {
      success: true,
      response: response?.data || response
    };
  } catch (error) {
    return {
      success: false,
      response: error
    };
  }
}

export async function getStampDutyCalculatorResultPropertyID(
  propertyId: string,
  isFirstTimeBuyer: boolean,
  isUkResident: boolean,
  isSecondHome: boolean
) {
  const url = `${API_ENDPOINTS.stampDutyCalculationResult}?IsUkResident=${isUkResident}&IsFirstTimeBuyer=${isFirstTimeBuyer}&IsSecondHome=${isSecondHome}&propertyId=${propertyId}`;

  try {
    const response = await httpRequest({
      url: `${url}`
    });

    return {
      success: true,
      response: response?.data || response
    };
  } catch (error) {
    return {
      success: false,
      response: error
    };
  }
}

export async function getBlogList({
  page = 1,
  pageSize = 12,
  category = '',
  searchTeam = ''
}: {
  page?: string | number;
  category?: string;
  pageSize?: string | number;
  searchTeam?: string;
}) {
  // request method and endpoint configuration

  try {
    const queryParams = new URLSearchParams();

    queryParams.set('Page', page.toString());
    queryParams.set('PageSize', pageSize.toString());

    if (category) {
      queryParams.set('categories', category);
    }

    if (searchTeam) {
      queryParams.set('searchTerm', searchTeam);
    }

    const response = await httpRequest({
      url: `${API_ENDPOINTS.blogArticle}?${queryParams.toString()}`
    });

    return {
      success: true,
      response
    };
  } catch (error) {
    return {
      success: false,
      response: error
    };
  }
}

export async function getBlogListPageContent() {
  const options = {
    url: `${API_ENDPOINTS.cms.blogListPageContent}`
  };

  try {
    const response = await httpCMSRequest(options);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getPageUrlList() {
  const options = {
    url: `${API_ENDPOINTS.cms.urlManagement}`
  };

  try {
    const response = await httpCMSRequest(options);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getPageContent(api: string) {
  const options = {
    url: `${api}`
  };

  try {
    const response = await httpCMSRequest(options);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getNearbyAreas(universityPostcodeShort: string) {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getNearbyAreas(universityPostcodeShort)
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getUniversitiesList(page: number, countPerPage: number) {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.universitiesListApi
      .replace('{page}', `${page}`)
      .replace('{countPerPage}', `${countPerPage}`)
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getUniversityDetail(slug: string) {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getUniversityDetailApi(slug)
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getUniversityDetailContent() {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.cms.universityDetail
  };

  try {
    const response = await httpCMSRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getValuationPageSeo() {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.cms.valuationSEO
  };

  try {
    const response = await httpCMSRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getPropertyListContent() {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.cms.propertyList
  };

  try {
    const response = await httpCMSRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getInteriorsDetailContent() {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.cms.interiorDetailPage
  };

  try {
    const response = await httpCMSRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getPropertyListFilters(filters: string) {
  const config: RequestConfig = {
    method: 'GET',
    url: `${API_ENDPOINTS.searchProperties}${filters}`
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getStationLines(propertyTitle: string) {
  const config: RequestConfig = {
    method: 'GET',
    url: `${API_ENDPOINTS.stationConnectingLines(propertyTitle)}`
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getUserFavoritePropertiesList() {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.savedProperties
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getPropertyDetailContent() {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.cms.propertyDetail
  };

  try {
    const response = await httpCMSRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getPropertyByMarketingId(marketingId: string): Promise<{
  success: boolean;
  response: null | {
    data: {
      instructionType: string;
      propertyReference: boolean;
      postcodeShort: string;
      locationWebName: string;
    };
  };
  error: unknown;
}> {
  const config: RequestConfig = {
    method: 'GET',
    host: getEnvVarOrDefault(process.env.NEXT_PUBLIC_FXSEARCH_BASE_URL),
    url: `${API_ENDPOINTS.getPropertyByMarketingId}/${marketingId}`
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: false,
      response: null,
      error
    };
  }
}

export async function getPropertyDetail(
  instructionType: string,
  propertyReference: string
): Promise<{
  success: boolean;
  response: null | {
    data: {
      instructionType: string;
      isSneakPeek: boolean;
      postcodeShort: string;
    };
  };
  error: unknown;
}> {
  const config: RequestConfig = {
    method: 'GET',
    host: getEnvVarOrDefault(process.env.NEXT_PUBLIC_FXSEARCH_BASE_URL),
    url: `${API_ENDPOINTS.searchProperty}/${propertyReference}?instructionType=${instructionType}`
  };

  try {
    const response = await httpRequest(config);

    if (response.status === 204) {
      return {
        success: true,
        response: null,
        error: null
      };
    }

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getPropertyDetailWithoutParams(
  propertyReference: string
) {
  const config: RequestConfig = {
    method: 'GET',
    url: `${API_ENDPOINTS.searchProperty}/${propertyReference}`
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getInteriorImageDetails(imageName: string) {
  const config: RequestConfig = {
    method: 'GET',
    url: `${API_ENDPOINTS.getInteriorImageDetail(imageName)}`
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getNearByStations(propertyReference: string) {
  const config: RequestConfig = {
    method: 'GET',
    // url: `${API_ENDPOINTS.searchProperty}/${propertyReference}/nearest-stations`
    url: `${API_ENDPOINTS.transport}/property/${propertyReference}/nearest-stations`
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getRecommendedProperties(
  propertyReference: string,
  propType: string,
  size: string // will return 1 less property than the defined size
) {
  const config: RequestConfig = {
    method: 'GET',
    // url: `${API_ENDPOINTS.searchProperty}/${propertyReference}/nearest-stations`
    // url: `${API_ENDPOINTS.recommendedProperties}/property/${propertyReference}/nearest-stations`
    url: `${API_ENDPOINTS.recommendedProperties}?PageSize=${size}&propertyId=${propertyReference}&SearchType=${propType}`
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getNearByLocalLife(propertyReference: string) {
  const config: RequestConfig = {
    method: 'GET',
    url: `${API_ENDPOINTS.getLocalLife(propertyReference)}`
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getNewHomesProperties(propertyName: string) {
  const config: RequestConfig = {
    method: 'GET',
    url: `${API_ENDPOINTS.getNewHomes}/${propertyName}`
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getNewHomesStrapiData(type: DevelopmentInstructionType) {
  const config: RequestConfig = {
    method: 'GET',
    url:
      type === DevelopmentInstructionType.LETTING
        ? API_ENDPOINTS.cms.buildToRentInfo
        : API_ENDPOINTS.cms.newHomesInfo
  };

  try {
    const response = await httpCMSRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getNewHomesPropertiesList() {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.searchNewHomesLinks
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getNewHomesPropertiesBySearchTerm(
  searchTerm: string,
  instructionType: DevelopmentInstructionType
) {
  const queryParams = new URLSearchParams();

  queryParams.append('instructionTypes', instructionType);
  queryParams.append('searchTerm', searchTerm);

  const config: RequestConfig = {
    method: 'GET',
    url: `${API_ENDPOINTS.getNewHomes}?${queryParams.toString()}`
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getGeneralContactForm() {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.cms.generalContactForm
  };

  try {
    const response = await httpCMSRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export const userSubscriptionFetcher = async (userId: string) => {
  const endpoint = API_ENDPOINTS.getUserSubscriptions.replace(
    ':userId',
    userId
  );
  const config: RequestConfig = {
    method: 'GET',
    url: endpoint
  };

  const response = await httpRequest(config);

  return response;
};

export const updateUserSubscriptionSetter = async (
  userId: string,
  data: UpdatedSubscriptions[]
) => {
  const config: RequestConfig = {
    method: 'PUT',
    url: API_ENDPOINTS.unsubUserSubscriptions.replace(':userId', userId),
    data
  };
  const response = await httpRequest(config);

  return response;
};

export const useSwrFetcher = async (
  endpoint: string,
  requestMethod: Method
) => {
  const config: RequestConfig = {
    method: requestMethod,
    url: endpoint
  };

  const response = await httpRequest(config);

  return response;
};

export const getAllNewHomes = async (
  instructionType: DevelopmentInstructionType
) => {
  const queryParams = new URLSearchParams();

  queryParams.append('instructionTypes', instructionType);

  const config: RequestConfig = {
    method: 'GET',
    url: `${API_ENDPOINTS.getNewHomes}?${queryParams.toString()}`
  };

  const response = await httpRequest(config);

  return response;
};

export async function getOfficeDetail(officeId: string, isDivision = false) {
  const config: RequestConfig = {
    method: 'GET',
    url: `${API_ENDPOINTS.getOfficeDetails(officeId)}${
      isDivision ? '/division' : ''
    }`
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getPropertyStatsDetail(
  officeCode: string,
  instructionType: string
) {
  const config: RequestConfig = {
    method: 'GET',
    url: `${API_ENDPOINTS.getPropertyStats(officeCode, instructionType)}`
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export const getJumpToQueueList = async (type: string) => {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getJumpToQueueList?.replace(':type', type)
  };

  const response = await httpRequest(config);

  return response;
};

export const createUserAlerts = async (
  userId: string,
  data: CreateAlertProps
) => {
  const config: RequestConfig = {
    method: 'POST',
    url: API_ENDPOINTS.createAlerts?.replace(':contactId', userId),
    data
  };
  const response = await httpRequest(config);

  return response;
};

export const userContactSubscriptions = async (
  userId: string,
  data: ContactSubscription,
  categoryId: string
) => {
  const config: RequestConfig = {
    method: 'PUT',
    url: API_ENDPOINTS.contactSubscriptions
      ?.replace(':userId', userId)
      ?.replace(':categoryId', categoryId),
    data
  };
  const response = await httpRequest(config);

  return response;
};

export async function getSitemapAreaAroundStation() {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.sitemapStations
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getSitemapArea(type: 'areas' | 'postcodes') {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.sitemapAreas(type)
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

//ESTATE AGENT APIS:
export async function getStrapiEstateAgentDetailData() {
  // request method and endpoint configuration
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getStrapiEstateAgentDetails
  };

  try {
    const response = await httpCMSRequest(config);

    return {
      success: true,
      response: response?.data?.data?.attributes
    };
  } catch (error) {
    return { success: false, response: error };
  }
}

export async function getStrapiNewHomesEstateAgentDetailData() {
  // request method and endpoint configuration
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getStrapiNewHomesEstateAgents
  };

  try {
    const response = await httpCMSRequest(config);

    return {
      success: true,
      response: response?.data?.data?.attributes
    };
  } catch (error) {
    return { success: false, response: error };
  }
}

export async function getStrapiEstateAgentPostcodeData() {
  // request method and endpoint configuration
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getStrapiEstateAgentPostCode
  };

  try {
    const response = await httpCMSRequest(config);

    return {
      success: true,
      response: response?.data?.data?.attributes
    };
  } catch (error) {
    return { success: false, response: error };
  }
}

export async function getStrapiEstateAgentRegionData() {
  // request method and endpoint configuration
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getStrapiEstateAgentRegion
  };

  try {
    const response = await httpCMSRequest(config);

    return {
      success: true,
      response: response?.data?.data?.attributes
    };
  } catch (error) {
    return { success: false, response: error };
  }
}

export async function getEstateAgentTestimonial(officeCode: string) {
  // request method and endpoint configuration
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getEstateAgentTestimonials(officeCode)
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getEstateAgentOfficeName() {
  // request method and endpoint configuration
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getEstateAgentOfficeByName()
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getEstateAgentWebName(webname: string) {
  // request method and endpoint configuration
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getEstateAgentByWebName(webname)
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getOfficeRegions(region: string) {
  // request method and endpoint configuration
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getOfficeRegion(region)
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getEstateAgentByPostcode(areaWebName: string) {
  // request method and endpoint configuration
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getEstateAgentPostcode(areaWebName)
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getEstateAgentsAreasCovered(officeId: number) {
  // request method and endpoint configuration
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getEstateAgentAreasCovered(officeId)
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getEstateAgnetNearestStations(
  lat: number,
  lon: number,
  distanceInMiles: number,
  take: number
) {
  // request method and endpoint configuration
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getEstateAgnetNearestStation(
      lat,
      lon,
      distanceInMiles,
      take
    )
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

//
export async function searchInBoundedBox(
  payload: {
    boundingBox: {
      topLeft: {
        lat: number;
        lon: number;
      };
      bottomRight: {
        lat: number;
        lon: number;
      };
    };
    includeRecentLet: boolean;
    includeRecentSold: boolean;
    includeRent: boolean;
    includeSale: boolean;
  },
  options?: { signal?: AbortController['signal'] }
) {
  const config: RequestConfig = {
    method: 'POST',
    url: API_ENDPOINTS.searchInBoundedBox,
    data: payload,
    ...options
  };

  try {
    const response = await httpRequest(config);

    return { success: true, response, error: null };
  } catch (error) {
    return { success: false, response: null, error };
  }
}

export const getLocalLifeData = async (page: number, seed: string) => {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getLocalLifeCards(page, seed)
  };
  const response = await httpRequest(config);

  return response;
};

export const getInteriorDesignCategories = async () => {
  const config: RequestConfig = {
    method: 'GET',
    url: `${API_ENDPOINTS.getInteriorDesignCategoriesApi}`
  };

  const response = await httpRequest(config);

  return response.data;
};

export const getStrapiSitemap = async (proptType: string, dataType: string) => {
  const headerConfig: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.cms.sitemapStrapi(proptType, dataType)
  };

  const result = await httpCMSRequest(headerConfig);

  const strapiResult = result.data?.data?.attributes;

  return strapiResult;
};

export const getStrapiSitemapGeneral = async () => {
  const headerConfig: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.cms.sitemapGeneralStrapi
  };

  const result = await httpCMSRequest(headerConfig);

  const strapiResult = result.data?.data?.attributes;

  return strapiResult;
};

export async function getTestimonialCards(type: string) {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getTestimonials(type)
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getLocalExpert(officeCode: string) {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getOfficeData(officeCode)
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response: response?.data,
      error: null
    };
  } catch (error) {
    return {
      success: false,
      response: null,
      error
    };
  }
}

export const getOfficesList = async () => {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getAllOffices
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response: response?.data,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
};

export const getNearestOffices = async (area: string) => {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.nearestOffices + area
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response: response?.data,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
};

export const getLocationsInBoundingBox = async (
  latitudeMin: number,
  latitudeMax: number,
  longitudeMin: number,
  longitudeMax: number,
  mapClassification: string,
  type: string
) => {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getLocationsInBoundingBox(
      latitudeMin,
      latitudeMax,
      longitudeMin,
      longitudeMax,
      mapClassification,
      type
    )
  };
  const response = await httpRequest(config);

  return response;
};

export async function getPropertiesNearByAreas(payload: string) {
  // request method and endpoint configuration
  const config: RequestConfig = {
    method: 'GET',
    url: `${API_ENDPOINTS.autoCompleteProperty}${payload}&className=place`
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response: response?.data
    };
  } catch (error) {
    return { success: false, response: error }; // call reducer to match case of fail and update store
  }
}

export const getLocalLifeLocationDetails = async (name: string) => {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getLocalLifeLocationDetail(name)
  };
  const response = await httpRequest(config);

  return response;
};

export const getLocalLifePoiDetails = async (
  type: string,
  polygon: string,
  pageSize: string
) => {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getLocalLifePoiDetails(type, polygon, pageSize)
  };
  const response = await httpRequest(config);

  return response;
};

export const getIncreaseInSale = async (area: string) => {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getIncreaseInSale(area)
  };
  const response = await httpRequest(config);

  return response;
};

export async function getCardImages(propertyReference: string) {
  // request method and endpoint configuration
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getImages(propertyReference)
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response: response?.data
    };
  } catch (error) {
    return { success: false, response: error };
  }
}

export async function getSimiliarProperties(
  propType: string,
  size: string,
  filters: string
) {
  const config: RequestConfig = {
    method: 'GET',
    url: `${API_ENDPOINTS.recommendedProperties}?PageSize=${size}&SearchType=${propType}${filters}`
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getStrapiLocalLifeDetailData() {
  // request method and endpoint configuration
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getStrapiLocalLifeDetails
  };

  try {
    const response = await httpCMSRequest(config);

    return {
      success: true,
      response: response?.data?.data?.attributes
    };
  } catch (error) {
    return { success: false, response: error };
  }
}

export async function getPropertiesForLocalLife(keyword: string) {
  // request method and endpoint configuration
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getPropertiesForLocalLife(keyword)
  };

  try {
    const response = await httpRequest(config);

    return response;
  } catch (error) {
    return { success: false, response: error };
  }
}

export async function postChineseContactForm(payload: {
  developmentName: string;
  name: string;
  email: string;
  mobileNo: string;
  address: string; //honeypot attribute
}) {
  const config: RequestConfig = {
    method: 'POST',
    url: API_ENDPOINTS.chineseContactUsForm,
    data: payload
  };

  try {
    const response = await httpRequest(config);

    return { success: true, response, error: null };
  } catch (error) {
    return { success: false, response: null, error };
  }
}

export async function getCorporateContactForm() {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.cms.corporateContactForm
  };

  try {
    const response = await httpCMSRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function postCorporateContactForm(payload: {
  title: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  mobileTelephone: string;
  comments: string;
  company: string;
  services: string;
  address: string;
}) {
  const config: RequestConfig = {
    method: 'POST',
    url: API_ENDPOINTS.corporateContactUs,
    data: payload
  };

  try {
    const response = await httpRequest(config);

    return { success: true, response, error: null };
  } catch (error) {
    return { success: false, response: null, error };
  }
}

export async function getPropertiesDevelopment(propertyName: string) {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.cms.getPropertyDevelopment(propertyName)
  };

  try {
    const response = await httpCMSRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getNearestOfficesContent() {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.cms.nearestOfficesContent
  };

  try {
    const response = await httpCMSRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getPropertiesForNewDevelopment(propertyName: string) {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getUnitsForNewDevelopment(propertyName)
  };

  try {
    const response = await httpRequest(config);

    return { response };
  } catch (error) {
    return { success: false, response: error };
  }
}

export async function getDataForLocalLifeRightDrawer(propertyId: string) {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getDrawerData(propertyId)
  };

  try {
    const response = await httpRequest(config);

    return { response };
  } catch (error) {
    return { success: false, response: error };
  }
}

export async function postLanlordEnquiryForm(payload: {
  title: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  mobileTelephone: string;
  comments: string;
  subject: string;
  formName: string;
  developmentReference: string;
  address: string;
}) {
  const config: RequestConfig = {
    method: 'POST',
    url: API_ENDPOINTS.lanlordEnquiry,
    data: payload
  };

  try {
    const response = await httpRequest(config);

    return { success: true, response, error: null };
  } catch (error) {
    return { success: false, response: null, error };
  }
}

export async function getLandlordEnquiryForm() {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.cms.landlordEnquiryForm
  };

  try {
    const response = await httpCMSRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getLandlordEnquiryPropertManagementForm() {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.cms.landlordEnquiryPropertyManagementForm
  };

  try {
    const response = await httpCMSRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function sellEnquiryCallbackForm() {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.cms.sellEnquiryCallbackForm
  };

  try {
    const response = await httpCMSRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function sellEnquiryQuestionForm() {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.cms.sellEnquiryQuestionForm
  };

  try {
    const response = await httpCMSRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function sellEnquiryInvestorForm() {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.cms.sellEnquiryInvestorForm
  };

  try {
    const response = await httpCMSRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function postSellEnquiryCallbackForm(payload: {
  title: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  workTelephone: string;
  comments: string;
  postCode: string;
  valuationMethod: string;
  valuationType: string;
  valuationAddress: {
    addressLine1: string;
    addressTown: string;
    addressPostcode: string;
  };
  webInterest: {
    seller: boolean;
    landlord: boolean;
    buyer: boolean;
    renter: boolean;
  };
}) {
  const config: RequestConfig = {
    method: 'POST',
    url: API_ENDPOINTS.sellEnquiryCallback,
    data: payload
  };

  try {
    const response = await httpRequest(config);

    return { success: true, response, error: null };
  } catch (error) {
    return { success: false, response: null, error };
  }
}

export async function getSchoolPerformanceTableList() {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getSchoolPerformanceTable
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response: response?.data,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getFeedBackOffices() {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.feedbackOfficeList
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getContactFeedbackForm() {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.cms.contactFeedbackForm
  };

  try {
    const response = await httpCMSRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function postContactFeedbackForm(payload: {
  specificEmployeeName: string;
  comments: string;
  office: string;
  title: string;
  name: string;
  email: string;
  phone: string;
  rating: number;
  address: string;
}) {
  const config: RequestConfig = {
    method: 'POST',
    url: API_ENDPOINTS.contactFeedback,
    data: payload
  };

  try {
    const response = await httpRequest(config);

    return { success: true, response, error: null };
  } catch (error) {
    return { success: false, response: null, error };
  }
}

export async function getMortgageLenders(
  mortgageType: string,
  expectedValuation: string,
  numberOfItems: string,
  term: string,
  paymentMethod: string,
  fixed: string,
  overpaymentsAllowed: string,
  reasonForMortgage: string,
  applicantType: string
) {
  const config: RequestConfig = {
    method: 'GET',
    url: `${API_ENDPOINTS.mortgageDeals}?mortgageType=${mortgageType}&expectedValuation=${expectedValuation}&numberOfItems=${numberOfItems}&term=${term}&paymentMethod=${paymentMethod}&fixed=${fixed}&overpaymentsAllowed=${overpaymentsAllowed}&reasonForMortgage=${reasonForMortgage}&applicantType=${applicantType}`
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response: response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getAssetsList() {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getAssetsListWeb
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response: response?.data,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getEstateAgents(postCode: string) {
  const config: RequestConfig = {
    method: 'GET',
    url: `${API_ENDPOINTS.getEstateAgents}/${postCode}`
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response: response?.data,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getAreaPolygons() {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.livingInPolygons
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response: response?.data,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export const getPostalCodeLocations = async (searchTerm: string) => {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getLocations(searchTerm)
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response: response?.data,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
};

export const getAveragePropertyPricesByArea = async (areaName: string) => {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getAveragePropertyPricesByArea(areaName)
  };
  const response = await httpRequest(config);

  return response;
};

export const getLocationDetails = async (areaName: string) => {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getLocationDetails(areaName)
  };
  const response = await httpRequest(config);

  return response;
};

export async function getMarketMeasuresProperties(
  encodedPolygon: string,
  lat: number,
  lon: number,
  take: number
) {
  const config: RequestConfig = {
    method: 'GET',
    url: `${API_ENDPOINTS.marketMeasuresProperties}?take=${take}&latitude=${lat}&longitude=${lon}&encodedPolygon=${encodedPolygon}`
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getMarketDetailPageContent() {
  const options = {
    url: '/api/get-single-type-content?strapiAPI=api/house-price?populate=deep'
  };

  try {
    const response = await httpCMSRequest(options);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getRentalMarketDetailPageContent() {
  const options = {
    method: 'GET',
    url: API_ENDPOINTS.cms.rentalHouseDetail
  };

  try {
    const response = await httpCMSRequest(options);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getAverageHouseMarketDetailPageContent() {
  const options = {
    method: 'GET',
    url: API_ENDPOINTS.cms.averageHouseDetail
  };

  try {
    const response = await httpCMSRequest(options);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

// Impersonation request
export interface EmployeeInterface {
  adminAuthToken: string;
  username: string;
}

export async function loginEmployee(payload: EmployeeInterface) {
  const { adminAuthToken, username } = payload;
  const config: RequestConfig = {
    method: 'POST',
    headers: {
      adminAuthToken: adminAuthToken ?? '',
      'Content-Type': 'application/json'
    },
    url: `${API_ENDPOINTS.account}/${API_ENDPOINTS.loginEmployee}`,
    data: { email: username }
  };

  try {
    const user = await httpRequest(config);

    return user.data;
  } catch (error) {
    return null;
  }
}

export async function getStrapiSoldPropertyDetailData() {
  // request method and endpoint configuration
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getStrapiSoldPropertyDetailInfo
  };

  try {
    const response = await httpCMSRequest(config);

    return {
      success: true,
      response: response?.data?.data?.attributes
    };
  } catch (error) {
    return { success: false, response: error };
  }
}

export async function getAllSchoolsListforSchoolPg(schoolWebName: string) {
  const config: RequestConfig = {
    method: 'GET',
    url: `${API_ENDPOINTS.schoolList}?leaName=${schoolWebName}`
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export const getStrapiSchoolDetails = async () => {
  const headerConfig: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.cms.schoolDetailsStrapi
  };

  try {
    const response = await httpCMSRequest(headerConfig);

    return {
      success: true,
      response: response?.data?.data?.attributes
    };
  } catch (error) {
    return { success: false, response: error };
  }
};

export const getStrapiSchoolShortlist = async () => {
  const headerConfig: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.cms.schoolShortlistStrapi
  };

  try {
    const response = await httpCMSRequest(headerConfig);

    return {
      success: true,
      response: response?.data?.data?.attributes
    };
  } catch (error) {
    return { success: false, response: error };
  }
};

export const getSchoolDetailPage = async () => {
  const headerConfig: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.cms.schoolDetailPage
  };

  try {
    const response = await httpCMSRequest(headerConfig);

    return {
      success: true,
      response: response?.data?.data?.attributes
    };
  } catch (error) {
    return { success: false, response: error };
  }
};

export async function getSchoolDetailContent(schoolWebName: string) {
  const config: RequestConfig = {
    method: 'GET',
    url: `${API_ENDPOINTS.getUniversityDetailApi(schoolWebName)}`
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

interface PrequalifiedPayload {
  contactId: string;
  applicantCardId: string;
  applicantCommunicationId: string;
  officeCode: string;
  instructionId?: string;
  instructionType?: string;
  timestamp?: string;
}

export async function getIsPrequalified(payload: PrequalifiedPayload): Promise<{
  success: boolean;
  response: null | {
    data?: {
      qualified: boolean;
      instructionType: string | null;
      propertyReference: string | null;
      newApplicantCardRequired: boolean;
    };
  };
  error: unknown;
}> {
  const url = API_ENDPOINTS.getIsPrequalified(
    payload.contactId,
    payload.applicantCardId,
    payload.applicantCommunicationId,
    payload.officeCode,
    payload.instructionId,
    payload.timestamp
  );

  const config: RequestConfig = {
    method: 'GET',
    url
  };

  try {
    const response = (await httpRequest(config)) as Response;

    return { success: true, response, error: null };
  } catch (error) {
    return { success: false, response: null, error };
  }
}

export async function getCareersPageContent() {
  const options = {
    url: API_ENDPOINTS.getCareersPageContent
  };

  try {
    const response = await httpCMSRequest(options);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: false,
      response: null,
      error
    };
  }
}

export async function getOpportunityDetailPageContent() {
  const options = {
    url: API_ENDPOINTS.getOpportunityDetailPageContent
  };

  try {
    const response = await httpCMSRequest(options);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: false,
      response: null,
      error
    };
  }
}

export const getJobsFromEployByRoleName = async (role: string) => {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getJobsFromEployByRoleName(role)
  };

  const response = await httpRequest(config);

  return response;
};

export async function getSearchInteriorsPhotos(payload) {
  const config: RequestConfig = {
    method: 'POST',
    url: `${API_ENDPOINTS.getInteriorDesignCategoriesApi}`,
    data: payload
  };

  try {
    const response = await httpRequest(config);

    return response?.data;
  } catch (error) {
    return null;
  }
}

export const getStrapiDataForInteriorPage = async () => {
  const headerConfig: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.cms.getStrapiData
  };

  try {
    const response = await httpCMSRequest(headerConfig);

    return {
      success: true,
      response: response?.data?.data
    };
  } catch (error) {
    return { success: false, response: error };
  }
};

export const getSoldHousePrices = async () => {
  const headerConfig: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.cms.soldHousePrices
  };

  try {
    const response = await httpCMSRequest(headerConfig);

    return {
      success: true,
      response: response?.data?.data?.attributes
    };
  } catch (error) {
    return { success: false, response: error };
  }
};

export async function getTestimonialPageContent() {
  const options = {
    url: API_ENDPOINTS.getTestimonialPageContent
  };

  try {
    const response = await httpCMSRequest(options);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: false,
      response: null,
      error
    };
  }
}

export const getAwardsDetails = async () => {
  const headerConfig: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.cms.getAwardsDetails
  };

  try {
    const response = await httpCMSRequest(headerConfig);

    return {
      success: true,
      response: response?.data?.data?.attributes
    };
  } catch (error) {
    return { success: false, response: error };
  }
};

export const getAllJobsFromEploy = async () => {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getAllJobsFromEploy
  };

  const response = await httpRequest(config);

  return response;
};

export async function getNewTenantFormPage() {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.cms.newTenantFormPage
  };

  try {
    const response = await httpCMSRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: false,
      response: null,
      error
    };
  }
}

export async function postChangeOfOccupancy(
  payload: ChangeOfOccupancyRequestPayload
) {
  const config: RequestConfig = {
    method: 'POST',
    url: `${API_ENDPOINTS.changeOfOccupancy}`,
    data: payload
  };

  try {
    const response = await httpRequest(config);

    return { success: true, response, error: null };
  } catch (error) {
    return { success: false, response: null, error };
  }
}

export async function getBlogArticles(
  page: number,
  pageSize: number,
  category?: string
) {
  const queryParams = new URLSearchParams();

  if (category) {
    queryParams.append('category', category);
  }

  queryParams.append('Page', page.toString());
  queryParams.append('PageSize', pageSize.toString());

  const config: RequestConfig = {
    method: 'GET',
    url: `${
      API_ENDPOINTS.getBlogsListApi.split('?')[0]
    }?${queryParams.toString()}`
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: false,
      response: null,
      error
    };
  }
}

/**
 * It should be called from new homes or build to rent pages
 */
export async function submitViewingEnquiry(
  formValues: ArrangeViewingsFieldProps,
  property: {
    propertyId: string;
    class: string;
    propertyReference: string;
    instructionType: string;
    officeId: string;
    address: string;
    postcodeShort: string;
    price: string;
  }
) {
  const [dateFrom, dateTo] = formValues.time;

  dateFrom.setMinutes(0);
  dateFrom.setSeconds(0);
  dateFrom.setMilliseconds(0);
  dateTo.setMinutes(0);
  dateTo.setSeconds(0);
  dateTo.setMilliseconds(0);

  let propertyInstructionType: string;

  if (property.instructionType === 'letting') {
    // build to rent, BOS doesn't have a special code for it
    propertyInstructionType = 'LON';
  } else {
    // new homes
    propertyInstructionType = property.class === 'unit' ? 'DDI' : 'DDV';
  }

  const payload: SubmitViewingEnquiryPayload = {
    contactEmailAddress: formValues.email,
    contactTitle: formValues.title,
    contactFirstName: formValues.firstName,
    contactLastName: formValues.lastName,
    contactHomeTelephone: formValues.homePhone ?? '',
    contactMobileTelephone: formValues.phoneNumber,
    message: `Viewing Times:\n- between ${formatDateTime(
      dateFrom
    )} and ${formatDateTime(dateTo)}\n\n ${formValues.preference}`,
    propertyId: property.propertyId,
    propertyInstructionType,
    propertyOurReference: property.propertyReference,
    submittedDateTime: new Date().toISOString(),
    enquiryTypes: [
      'REQUEST_VIEWING',
      property.instructionType === 'sale' ? 'LOOKING_TO_BUY' : 'LOOKING_TO_RENT'
    ],
    source: 'Foxtons',
    contactAddress: '',
    contactCountryCode: '',
    contactMovingReason: '',
    contactPostcode: '',
    contactPropertyStatus: '',
    contactSearchParams: '',
    contactSearchUrl: '',
    contactWorkTelephone: '',
    officeId: property.officeId,
    propertyAddress: property.address,
    propertyAggregatorReference: '',
    propertyAggregatorUrl: '',
    propertyPostcode: property.postcodeShort,
    propertyPrice: property.price,
    propertyStyle: ''
  };

  const config: RequestConfig = {
    method: 'POST',
    url: API_ENDPOINTS.submitViewingEnquiry,
    data: payload
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: false,
      response: null,
      error
    };
  }
}

export async function getPreQualificationQuestions({
  contactId,
  applicantCardId,
  applicantCommunicationId,
  timestamp
}: {
  contactId: string;
  applicantCardId: string;
  applicantCommunicationId: string;
  timestamp?: string;
}): Promise<QuestionSchemaTypes | null> {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getPreQualificationQuestions({
      contactId,
      applicantCardId,
      applicantCommunicationId,
      timestamp
    })
  };

  try {
    const res = await httpRequest(config);

    if (res.data) {
      return res.data;
    }

    throw new Error('No data');
  } catch (error) {
    console.error(`> HTTP error fetching ${config.url}:`, error);
    throw error;
  }
}

export async function savePreQualificationAnswers({
  contactId,
  applicantCardId,
  payload
}: {
  contactId: string;
  applicantCardId: string;
  applicantCommunicationId: string;
  payload: AnswerPayloadType;
}) {
  const config: RequestConfig = {
    method: 'POST',
    url: API_ENDPOINTS.savePreQualificationAnswers({
      contactId,
      applicantCardId
    }),
    data: payload
  };

  try {
    const res = await httpRequest(config);
  } catch (error) {
    console.log('> HTTP error:', error);

    return null;
  }
}

export const createBidX1Session = async (
  { propertyReference, email }: { propertyReference: string; email?: string },
  signal?: AbortSignal
): Promise<{
  sessionId?: string;
  message?: string;
}> => {
  const queryParams = new URLSearchParams();

  queryParams.append('propertyReference', propertyReference);

  if (email) {
    queryParams.append('emailAddress', email);
  }

  const config: RequestConfig = {
    method: 'GET',
    url: `${API_ENDPOINTS.createBidX1Session}?${queryParams.toString()}`,
    signal
  };

  const response = await httpRequest(config);

  return response?.data;
};

export async function getPrivateOfficeContactPage() {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.cms.privateOfficeContact
  };

  try {
    const response = await httpCMSRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function submitPrivateOfficeContactForm(
  payload: ContactPrivateOfficePayload
) {
  const config: RequestConfig = {
    method: 'POST',
    url: `${API_ENDPOINTS.privateOfficeContact}`,
    data: payload
  };

  try {
    const response = await httpRequest(config);

    return { success: true, response, error: null };
  } catch (error) {
    return { success: false, response: null, error };
  }
}

export async function saveClientContactActivity(
  code: string,
  payload: {
    contactId: string;
    contactTrackingData: { key: string; value: string }[];
  }
) {
  const config: RequestConfig = {
    method: 'POST',
    url: `${API_ENDPOINTS.saveClientContactActivity}${code}`,
    data: payload
  };

  try {
    await httpRequest(config);
  } catch (error) {
    console.error('Error saving client contact activity', error);
  }
}

export async function getRecommendedInstructions(
  {
    applicantCardId,
    instructionId,
    propertyReference,
    instructionType
  }: {
    applicantCardId: string;
    instructionId: string;
    propertyReference: string;
    instructionType: string;
  },
  signal?: AbortSignal
) {
  const params = new URLSearchParams();

  params.set('property_reference', propertyReference);
  params.set('instruction_type', instructionType);

  const config: RequestConfig = {
    method: 'GET',
    url: `${API_ENDPOINTS.getRecommendedInstructions(
      applicantCardId,
      instructionId
    )}?${params.toString()}`,
    signal
  };

  try {
    const response = await httpRequest(config);

    return response.data;
  } catch (error) {
    return null;
  }
}

export async function submitInstructionVote(
  applicantCardId: string,
  instructionId: string,
  instructionVote: string
) {
  const config: RequestConfig = {
    method: 'PUT',
    url: API_ENDPOINTS.submitInstructionVote(
      applicantCardId,
      instructionId,
      instructionVote
    )
  };

  try {
    await httpRequest(config);

    return {
      success: true,
      error: null
    };
  } catch (error) {
    console.error('Error saving instruction vote', error);

    return {
      success: false,
      error
    };
  }
}

export async function getBOSRecommendedProperties(
  propertyReference: string,
  instructionType: string,
  size = 10
) {
  const params = new URLSearchParams();

  params.set('size', size.toString());
  const config: RequestConfig = {
    method: 'GET',
    url: `${API_ENDPOINTS.bosRecommendedProperties(
      propertyReference,
      instructionType
    )}?${params.toString()}`
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function wsiAuthLoginByContactType(
  contactType: string | number
): Promise<{
  success: boolean;
  response: Awaited<ReturnType<typeof httpRequest>> | null;
  error: unknown;
}> {
  const config: RequestConfig = {
    method: 'GET',
    url: `${API_ENDPOINTS.getWebTest}?webTestContactType=${contactType}`
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: false,
      response: null,
      error
    };
  }
}

export async function wsiAuthLoginByContactId(contactId: string | number) {
  const config: RequestConfig = {
    method: 'POST',
    url: API_ENDPOINTS.accountLogin,
    data: {
      email: `${contactId}@foxtons.co.uk`,
      password: 'password',
      rememberMe: true
    }
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: false,
      response: null,
      error
    };
  }
}

export async function fetchRecommendedProperties(type: string) {
  const filters = new URLSearchParams();

  filters.set('search_term', 'london');
  filters.set('search_type', type);
  filters.set('price_from', '2000');
  filters.set('page_size', '5');
  filters.set('include_pre_protog', 'false');

  const result = await getPropertyListFilters(`?${filters.toString()}`);

  if (result.success) {
    return result.response?.data?.data;
  }

  throw new Error('Error fetching recommended properties');
}

export async function getUserByContactId(contactId: string) {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getUserByContactId(contactId)
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function wsiAuthUpdateUserVerification(
  shouldVerify: boolean,
  user: any
) {
  const config: RequestConfig = {
    method: 'POST',
    url: shouldVerify
      ? `${API_ENDPOINTS.setWebTestVerify}`
      : `${API_ENDPOINTS.setWebTestUnverify}`,
    data: {
      username: user.web_username,
      contactId: user.contactId
    }
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function pingBOS() {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getWebTestPing
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function getBOSWsiHost(): Promise<{
  success: boolean;
  response: string[] | null;
  error: unknown;
}> {
  const config: RequestConfig = {
    method: 'GET',
    url: API_ENDPOINTS.getWebTestBosWsiHost
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response: response.data.hosts,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

export async function resendVerificationEmail(contactId: string) {
  const config: RequestConfig = {
    method: 'POST',
    url: `${API_ENDPOINTS.account}/${contactId}/${API_ENDPOINTS.verification}/${API_ENDPOINTS.resend}`
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: false,
      response: null,
      error
    };
  }
}
