import { getUserFromStorage, setUserIntoStorage } from './index';
import { getUserDetail } from './httpRequest';

/**
 * Only use this when failure of refresh is not critical.
 * UI won't be refreshed immediately because react is not notified here.
 */
export async function refreshUserSilently() {
  const { user } = getUserFromStorage();

  try {
    const { response } = await getUserDetail();

    if (response) {
      const userData = {
        profile: response || {},
        isLoggedIn: user?.isLoggedIn || !!response?.contactId,
        userName: response?.emailAddress || '',
        contactId: response?.contactId || ''
      };

      setUserIntoStorage(userData);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}
